/*
# **********************************************************************
#
#  (c) 2020 Tobias Fleckenstein <tfleckenstein@mp-group.net>, mp group GmbH
#
# **********************************************************************
# date/time    : 03.01.2020
# project      : Resopal Decors
# developer    : Tobias Fleckenstein
#
#   .-------------'```'----....,,__                        _,
#   |                               `'`'`'`'-.,.__        .'(
#   |                                             `'--._.'   )
#   |                                                   `'-.<
#   \               .-'`'-.                            -.    `\
#    \               -.o_.     _                     _,-'`\    |
#     ``````''--.._.-=-._    .'  \            _,,--'`      `-._(
#       (^^^^^^^^`___    '-. |    \  __,,..--'                 `
#        `````````   `'--..___\    |`
#                              `-.,'
# **********************************************************************
*/

@import '_variables';

html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 10px;
  margin: auto;

}
.loginBox{
  border: 1px solid $border-color;
  padding: 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.2);
  -moz-box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.2);
  box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.2);
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  height: 3.125rem;
  padding: .75rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: $font-default;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.userIcon{
  position: absolute;
  top: 17px;
  left: 10px;
}
.passwordIcon{
  position: absolute;
  top: 16px;
  left: 10px;
}
.userIcon + input,
.passwordIcon + input{
  padding-left: 30px;
}
.userIcon + input + label,
.passwordIcon + input + label{
  left: 18px;
}
.userIcon + input:focus + label,
.passwordIcon + input:focus + label{
  left: 18px;
}

.form-label-group input::-webkit-input-placeholder{
  color: transparent;
  opacity: 0;
}
.form-label-group input::-ms-input-placeholder{
  color: transparent;
  opacity: 0;
}
.form-label-group input::-ms-input-placeholder{
  color: transparent;
  opacity: 0;
}
.form-label-group input::-moz-placeholder{
  color: transparent;
  opacity: 0;
}
.form-label-group input:focus::-moz-placeholder{
  color: transparent;
  opacity: 0;
}
.form-label-group input::placeholder{
  color: transparent;
  opacity: 0;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: .25rem;
}
.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
  color: $font-default-lighten10;
}
/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: $font-default-lighten10;
    opacity: 1;
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: $font-default-lighten10;
  }
}
